<style scoped lang="less">
  @import './styles.less';
</style>

<template>
  <div class="patients-page">
    <div class="container">
      <div class="labels">
        <router-link tag="div" class="label" :class="{active: type === 'patients.details.info'}" :to="{name: 'patients.details.info', query: {id: id}}">电子病历</router-link>
        <router-link tag="div" class="label" :class="{active: type === 'patients.details.prescription'}" :to="{name: 'patients.details.prescription', query: {id: id}}">处方售药</router-link>
      </div>
      <div class="content">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    type () {
      return this.$route.name
    },
    id () {
      return this.$route.query.id || null
    }
  },
  beforeRouteEnter (to, from, next) {
    if (to.query.id) {
      next()
    } else {
      next({name: 'patients.index'})
    }
  }
}
</script>
